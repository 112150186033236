<template>
  <div class="reserved-area-page">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <form class="d-flex justify-content-between flex-column">
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">TREINOS</p>
              <div class="reserved-area-form-group">
                <div class="reserved-area-workout-list">
                  <div class="reserved-area-workout-item">
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="d-flex justify-content-start align-items-start">
                        <div class="reserved-area-workout-image">
                          <img src="http://via.placeholder.com/50" alt="">
                        </div>
                        <div class="reserved-area-workout-info">
                          <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                          <div class="workout-item-tags">
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <clock/>
                              </div>
                              <p>15 min.</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <level/>
                              </div>
                              <p>Intermédio</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <fire/>
                              </div>
                              <p>Iniciante</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="" class="reserved-area-workout-btn d-flex justify-content-center align-items-center">
                        <eyeIcon/>
                      </a>
                    </div>
                  </div>
                  <div class="reserved-area-workout-item">
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="d-flex justify-content-start align-items-start">
                        <div class="reserved-area-workout-image">
                          <img src="http://via.placeholder.com/50" alt="">
                        </div>
                        <div class="reserved-area-workout-info">
                          <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                          <div class="workout-item-tags">
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <clock/>
                              </div>
                              <p>15 min.</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <level/>
                              </div>
                              <p>Intermédio</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <fire/>
                              </div>
                              <p>Iniciante</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="" class="reserved-area-workout-btn d-flex justify-content-center align-items-center">
                        <eyeIcon/>
                      </a>
                    </div>
                  </div>
                  <div class="reserved-area-workout-item">
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="d-flex justify-content-start align-items-start">
                        <div class="reserved-area-workout-image">
                          <img src="http://via.placeholder.com/50" alt="">
                        </div>
                        <div class="reserved-area-workout-info">
                          <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                          <div class="workout-item-tags">
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <clock/>
                              </div>
                              <p>15 min.</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <level/>
                              </div>
                              <p>Intermédio</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <fire/>
                              </div>
                              <p>Iniciante</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="" class="reserved-area-workout-btn d-flex justify-content-center align-items-center">
                        <eyeIcon/>
                      </a>
                    </div>
                  </div>
                  <div class="reserved-area-workout-item">
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="d-flex justify-content-start align-items-start">
                        <div class="reserved-area-workout-image">
                          <img src="http://via.placeholder.com/50" alt="">
                        </div>
                        <div class="reserved-area-workout-info">
                          <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                          <div class="workout-item-tags">
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <clock/>
                              </div>
                              <p>15 min.</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <level/>
                              </div>
                              <p>Intermédio</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <fire/>
                              </div>
                              <p>Iniciante</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="" class="reserved-area-workout-btn d-flex justify-content-center align-items-center">
                        <eyeIcon/>
                      </a>
                    </div>
                  </div>
                  <div class="reserved-area-workout-item">
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="d-flex justify-content-start align-items-start">
                        <div class="reserved-area-workout-image">
                          <img src="http://via.placeholder.com/50" alt="">
                        </div>
                        <div class="reserved-area-workout-info">
                          <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                          <div class="workout-item-tags">
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <clock/>
                              </div>
                              <p>15 min.</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <level/>
                              </div>
                              <p>Intermédio</p>
                            </div>
                            <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                              <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                <fire/>
                              </div>
                              <p>Iniciante</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href="" class="reserved-area-workout-btn d-flex justify-content-center align-items-center">
                        <eyeIcon/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import eyeIcon from "@/components/svg/eye.vue";

export default {
  name: "reserved-area-index",
  components: {
    reservedAreaMenu,
    clock,
    level,
    fire,
    eyeIcon
  }
}
</script>